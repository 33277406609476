import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { EXHIBITOR_BASE_FRAGMENT } from '@/graphql/_Fragments/Exhibitor/Base';
import { SPONSOR_ROLE_FULL_FRAGMENT } from '@/graphql/_Fragments/SponsorRole/Full';
import { SPEAKER_ROLE_FOR_SESSION_FRAGMENT } from '@/graphql/_Fragments/SpeakerRole/ForSession';
import { CATEGORY_WITH_CONTEXT_FRAGMENT } from '@/graphql/_Fragments/Category/WithContext';

export const SESSION_FULL_FRAGMENT = `
  fragment sessionFullFragment on Session {
    uid
    id
    schemaCode
    name
    shortName
    description
    descriptionHtml
    startTime
    startTimestamp
    endTime
    endTimestamp
    speakers
    featured
    imageFileResource {
      ...fileResourceBaseFragment
    }
    bannerFileResource {
      ...fileResourceBaseFragment
    }
    exhibitor {
      ...exhibitorBaseFragment
    }
    categories {
      ...categoryWithContextFragment
    }
    speakerRoles {
      ...speakerRoleForSessionFragment
    }
    sponsorRoles {
      ...sponsorRoleFullFragment
    }
    _bookmarkCount
    _isBookmarked(myUid: "%authUser%")
    _isInAgenda(myUid: "%authUser%")
    _isRecommendedForMe
  }
  ${CATEGORY_WITH_CONTEXT_FRAGMENT}
  ${EXHIBITOR_BASE_FRAGMENT}
  ${SPONSOR_ROLE_FULL_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${SPEAKER_ROLE_FOR_SESSION_FRAGMENT}
`;
